<template>
  <portal>

    <v-row>
      <v-col cols="12">

        <v-breadcrumbs
          :items="breadcrumbsItems"
          class="pa-0 mb-8"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-row">

        <span class="d-flex align-center justify-center">
          <v-icon color="secondary" size="36" class="mr-3">mdi-account-group</v-icon>
          <h1 class="darkGrey--text">Meetings</h1>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="darkGrey"
                size="18"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>
              Explanation for Agenda Styles
            </span>
          </v-tooltip>
        </span>

        <v-spacer></v-spacer>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              depressed
              height="40"
              color="orange lighten-2"
              class="white--text"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon size="18">mdi-plus</v-icon> Add Meeting
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="openModal('create')"
              v-for="item in boardsSample"
              :key="item"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-col>
    </v-row>

    <v-row>
      <v-col>

        <v-tabs
          v-model="tab"
          centered
          color="secondary"
          background-color="transparent"
          class="custom-tab"
          icons-and-text
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-1">
            <span class="d-flex align-center">
              <v-icon class="d-flex mr-3">mdi-clock-fast</v-icon>
              In Development
            </span>
          </v-tab>
          <v-tab href="#tab-2">
            <span class="d-flex align-center">
              <v-icon class="d-flex mr-3">mdi-check-circle</v-icon>
              Finalized
            </span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
          class="transparent"
        >

          <v-tab-item
            value="tab-1"
            class="transparent"
          >

            <v-row class="d-flex mb-6 mt-6">
              <v-col
                class="d-flex"
                cols="5"
              >
                <v-select
                  :items="boardsSample"
                  label="Board"
                  v-model="boardSelected"
                  hide-details
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                cols="4"
              >
                <v-text-field
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-data-table
                    :headers="headersInDevelopment"
                    :items="meetingSample"
                    class="elevation-1"
                    :footer-props="{
                      'items-per-page-options': [10, 20, 30]
                    }"
                  >
                    <template v-slot:item.editDraft="{ item }">
                      <v-speed-dial
                        v-model="item.menu"
                        direction="right"
                        transition="slide-x-transition"
                      >
                        <template v-slot:activator>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-model="item.menu"
                                :color="item.menu?'grey':'secondary'"
                                dark
                                fab
                                depressed
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon v-if="item.menu">
                                  mdi-close
                                </v-icon>
                                <v-icon v-else>
                                  mdi-buffer
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>
                              Draft and Packet
                            </span>
                          </v-tooltip>
                        </template>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              color="secondary"
                              v-bind="attrs"
                              v-on="on"
                              @click="viewMeetingDraft(item)"
                            >
                              <v-icon size="22">
                                mdi-table-edit
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                              Draft
                            </span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab
                              color="secondary"
                              v-bind="attrs"
                              v-on="on"
                              @click="viewMeetingPacket(item)"
                            >
                              <v-icon size="22">
                                mdi-notebook
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                              Packet
                            </span>
                        </v-tooltip>
                      </v-speed-dial>
                    </template>
                    <template v-slot:item.board="{ item }">
                      <span class="py-6 d-flex flex-column">
                        <span class="font-weight-bold">
                          {{ item.board }}
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.meeting="{ item }">
                      <span class="d-flex py-6">
                        {{ item.meeting }}
                      </span>
                    </template>
                    <template v-slot:item.date="{ item }">
                      <span class="d-flex py-6 text-caption">
                        {{ item.date }}<br>
                        {{ item.time }}<br>
                        {{ item.weekday }}
                      </span>
                    </template>
                    <template v-slot:item.agendaStyle="{ item }">
                      <span class="d-flex py-6">
                        {{ item.agendaStyle }}
                      </span>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <div class="d-flex flex-row justify-center">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                              @click="viewMeetingItems(item)"
                            >
                              <v-icon size="20">
                                mdi-dns
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Meeting Items
                          </span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                              @click="viewMeeting(item)"
                            >
                              <v-icon size="20">
                                mdi-eye-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            View details
                          </span>
                        </v-tooltip>

                        <v-menu transition="slide-x-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon size="20">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item-group>
                              <v-list-item
                                v-for="(itemMenu, index) in itemsMenuInDev"
                                :key="index"
                                @click="openModal(itemMenu.actions, itemMenu.optional)"
                                dense
                              >
                                <v-list-item-icon>
                                  <v-icon
                                    :color="itemMenu.type? itemMenu.type:'darkGrey'"
                                  >
                                    {{ itemMenu.icon }}
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title
                                    :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                                  >
                                    {{ itemMenu.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>

                      </div>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>

          </v-tab-item>

          <v-tab-item
            value="tab-2"
          >

            <v-row class="d-flex mb-6 mt-6">
              <v-col
                class="d-flex"
                cols="5"
              >
                <v-select
                  :items="boardsSample"
                  label="Board"
                  v-model="boardSelected"
                  hide-details
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                cols="4"
              >
                <v-text-field
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-card>
                  <v-data-table
                    :headers="headersFinalized"
                    :items="meetingSample"
                    class="elevation-1"
                    :footer-props="{
                      'items-per-page-options': [10, 20, 30]
                    }"
                  >
                    <template v-slot:item.board="{ item }">
                      <span class="py-6 d-flex flex-column">
                        <span class="font-weight-bold">
                          {{ item.board }}
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.meeting="{ item }">
                      <span class="d-flex py-6">
                        {{ item.meeting }}
                      </span>
                    </template>
                    <template v-slot:item.date="{ item }">
                      <span class="d-flex py-6 text-caption">
                        {{ item.date }}<br>
                        {{ item.time }}<br>
                        {{ item.weekday }}
                      </span>
                    </template>
                    <template v-slot:item.agendaStyle="{ item }">
                      <span class="d-flex py-6">
                        {{ item.agendaStyle }}
                      </span>
                    </template>
                    <template v-slot:item.actions>
                      <div class="d-flex flex-row justify-center">

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                              @click="meetingUploads"
                            >
                              <v-icon size="20">
                                mdi-cloud-upload
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Uploads
                          </span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                              @click="meetingMinutes"
                            >
                              <v-icon size="20">
                                mdi-fountain-pen-tip
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Minutes
                          </span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              color="darkGrey"
                              size="18"
                              v-bind="attrs"
                              v-on="on"
                              @click="viewFinalized"
                            >
                              <v-icon size="20">
                                mdi-text-box-multiple-outline
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            View details
                          </span>
                        </v-tooltip>

                        <v-menu transition="slide-x-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              icon
                            >
                              <v-icon size="20">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item-group>
                              <v-list-item
                                v-for="(itemMenu, index) in itemsMenuFinalized"
                                :key="index"
                                @click="openModal(itemMenu.actions, itemMenu.optional)"
                                dense
                              >
                                <v-list-item-icon>
                                  <v-icon
                                    :color="itemMenu.type? itemMenu.type:'darkGrey'"
                                  >
                                    {{ itemMenu.icon }}
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title
                                    :class="itemMenu.type? itemMenu.type+'--text':'darkGrey--text'"
                                  >
                                    {{ itemMenu.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-menu>

                      </div>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>

          </v-tab-item>

        </v-tabs-items>

      </v-col>
    </v-row>

    <v-snackbar
      v-model="finalizingMeeting"
      timeout="-1"
      class="custom-snackbar"
    >
      <span class="d-flex align-center">
        <v-progress-linear
          color="secondary"
          rounded
          value="60"
          v-if="finalizingMeetingMinimized"
        ></v-progress-linear>
        <v-progress-circular
          :value="60"
          :width="3"
          color="secondary"
          v-if="!finalizingMeetingMinimized"
        ></v-progress-circular>
        <span
          class="d-flex flex-column ml-4"
          v-if="!finalizingMeetingMinimized"
        >
          <span class="text-uppercase text-caption">Finalizing</span>
          <span class="font-weight-bold">Name of Meeting</span>
        </span>
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          @click="viewFinalizeProgress"
          v-if="!finalizingMeetingMinimized"
        >
          <v-icon size="22">mdi-eye</v-icon>
        </v-btn>
        <v-btn
          icon
          v-bind="attrs"
          @click="finalizingMeetingMinimized = true"
          v-if="!finalizingMeetingMinimized"
        >
          <v-icon size="14">mdi-window-minimize</v-icon>
        </v-btn>
        <v-btn
          icon
          x-small
          v-bind="attrs"
          @click="finalizingMeetingMinimized = false"
          v-if="finalizingMeetingMinimized"
        >
          <v-icon size="14">mdi-window-maximize</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <meeting-create v-model="modals.create"></meeting-create>
    <meeting-edit v-model="modals.edit"></meeting-edit>
    <meeting-post-date-method v-model="modals.postMethod"></meeting-post-date-method>
    <meeting-auto-notifications v-model="modals.autoNotifications"></meeting-auto-notifications>
    <meeting-social-media v-model="modals.socialMedia"></meeting-social-media>
    <meeting-variables v-model="modals.variables"></meeting-variables>
    <meeting-unfinalize v-model="modals.unfinalize"></meeting-unfinalize>
    <meeting-hide v-model="modals.hide"></meeting-hide>
    <meeting-delete v-model="modals.delete"></meeting-delete>
    <meeting-view v-model="modals.view" v-if="modals.view"></meeting-view>
    <meeting-add-item v-model="modals.addItem"></meeting-add-item>

    <meeting-finalize v-model="modals.finalize" @callFinalizeMeeting="finalizeMeeting" v-if="modals.finalize"></meeting-finalize>
    <meeting-finalize-progress v-model="modals.finalizeProgress" @callFinalizeMeeting="finalizeMeeting" v-if="modals.finalizeProgress"></meeting-finalize-progress>

    <meeting-draft v-model="modals.draft" :builderType="builderType"></meeting-draft>

    <!-- SAME MODALS AS ITEMS -->
    <item-create v-model="modalsItems.create"></item-create>
    <item-edit v-model="modalsItems.edit"></item-edit>
    <item-type v-model="modalsItems.itemType"></item-type>
    <item-change-region v-model="modalsItems.region"></item-change-region>
    <item-cover-sheet v-model="modalsItems.coverSheet"></item-cover-sheet>
    <item-share-permissions v-model="modalsItems.permissions"></item-share-permissions>
    <item-conversations-notes v-model="modalsItems.conversations"></item-conversations-notes>
    <item-private v-model="modalsItems.private"></item-private>
    <item-move-to-meeting v-model="modalsItems.move"></item-move-to-meeting>
    <item-not-private v-model="modalsItems.notPrivate"></item-not-private>
    <item-delete v-model="modalsItems.delete"></item-delete>
    <item-view v-model="modalsItems.view" v-if="modalsItems.view"></item-view>

  </portal>
</template>

<script>
import Vue from 'vue'
import Portal from '@/components/Portal'
import MeetingCreate from '@/components/Meetings/MeetingCreate'
import MeetingEdit from '@/components/Meetings/MeetingEdit'
import MeetingAutoNotifications from '@/components/Meetings/MeetingAutoNotifications'
import MeetingPostDateMethod from '@/components/Meetings/MeetingPostDateMethod'
import MeetingSocialMedia from '@/components/Meetings/MeetingSocialMedia'
import MeetingVariables from '@/components/Meetings/MeetingVariables'
import MeetingFinalize from '@/components/Meetings/MeetingFinalize'
import MeetingFinalizeProgress from '@/components/Meetings/MeetingFinalizeProgress'
import MeetingUnfinalize from '@/components/Meetings/MeetingUnfinalize'
import MeetingHide from '@/components/Meetings/MeetingHide'
import MeetingDelete from '@/components/Meetings/MeetingDelete'
import MeetingView from '@/components/Meetings/MeetingView'
import MeetingAddItem from '@/components/Meetings/MeetingAddItem'

/* SAME COMPONENT FROM AGENDA STYLE */
// import MeetingDraft from '@/components/AgendaStyles/AgendaStyleRegions'
import MeetingDraft from '@/components/Meetings/MeetingDraft'
/* SAME COMPONENTS AS ITEMS */
import ItemCreate from '@/components/Items/ItemCreate'
import ItemEdit from '@/components/Items/ItemEdit'
import ItemType from '@/components/Items/ItemType'
import ItemChangeRegion from '@/components/Items/ItemChangeRegion'
import ItemCoverSheet from '@/components/Items/ItemCoverSheet'
import ItemSharePermissions from '@/components/Items/ItemSharePermissions'
import ItemConversationsNotes from '@/components/Items/ItemConversationsNotes'
import ItemPrivate from '@/components/Items/ItemPrivate'
import ItemMoveToMeeting from '@/components/Items/ItemMoveToMeeting'
import ItemNotPrivate from '@/components/Items/ItemNotPrivate'
import ItemDelete from '@/components/Items/ItemDelete'
import ItemView from '@/components/Items/ItemView'

export default Vue.extend({
  name: 'Meetings',
  components: {
    Portal,
    MeetingCreate,
    MeetingEdit,
    MeetingAutoNotifications,
    MeetingPostDateMethod,
    MeetingSocialMedia,
    MeetingVariables,
    MeetingFinalize,
    MeetingFinalizeProgress,
    MeetingUnfinalize,
    MeetingHide,
    MeetingDelete,
    MeetingView,
    MeetingAddItem,
    MeetingDraft,
    ItemCreate,
    ItemEdit,
    ItemType,
    ItemChangeRegion,
    ItemCoverSheet,
    ItemSharePermissions,
    ItemConversationsNotes,
    ItemPrivate,
    ItemMoveToMeeting,
    ItemNotPrivate,
    ItemDelete,
    ItemView
  },
  data: () => ({
    modeType: '',
    builderType: 'meeting',
    tab: 1,
    test1: false,
    test2: false,
    test3: false,
    finalizingMeeting: false,
    finalizingMeetingMinimized: false,
    testAdd: [
      'Header',
      'Agenda',
      'Call to Order',
      'Pledge of Allegiance',
      'Minutes',
      'Correspondence & Comments',
      'Consent Agenda',
      'Old Business',
      'New Business',
      'Items out of order',
      'Adjournment',
      'Accessible Statement',
      'Moment of Silence',
      'Executive Session'
    ],
    breadcrumbsItems: [
      {
        text: 'Meetings',
        disabled: true,
        href: ''
      }
    ],
    modals: {
      create: false,
      edit: false,
      postMethod: false,
      autoNotifications: false,
      socialMedia: false,
      addItem: false,
      variables: false,
      finalize: false,
      finalizeProgress: false,
      hide: false,
      delete: false,
      unfinalize: false,
      view: false,
      draft: false
    },
    modalsItems: {
      create: false,
      edit: false,
      itemType: false,
      region: false,
      coverSheet: false,
      permissions: false,
      conversations: false,
      private: false,
      download: false,
      move: false,
      notPrivate: false,
      delete: false,
      view: false
    },
    itemsMenuInDev: [
      { icon: 'mdi-pencil', text: 'Edit Label / Date / Time / Location', actions: 'edit' },
      { icon: 'mdi-bulletin-board', text: 'Post Date Method', actions: 'postMethod' },
      { icon: 'mdi-email', text: 'Auto-Notifications', actions: 'autoNotifications' },
      { icon: 'mdi-share-variant', text: 'Social Media', actions: 'socialMedia' },
      { icon: 'mdi-dns', text: 'Add Item', actions: 'addItem' },
      { icon: 'mdi-code-json', text: 'Meeting Variables', actions: 'variables' },
      { icon: 'mdi-power', text: 'Finalize', type: 'important', actions: 'finalize' },
      { icon: 'mdi-package-down', text: 'Hide', type: 'important', actions: 'hide' },
      { icon: 'mdi-delete', text: 'Delete', type: 'important', actions: 'delete' }
    ],
    itemsMenuFinalized: [
      { icon: 'mdi-pencil', text: 'Unfinalize', actions: 'unfinalize' },
      { icon: 'mdi-download', text: 'Download Agenda', actions: 'download' },
      { icon: 'mdi-download', text: 'Download Packet', actions: 'download' },
      { icon: 'mdi-download', text: 'Download Minutes', actions: 'download' }
    ],
    headersInDevelopment: [
      { text: '', value: 'editDraft', width: '80px' },
      { text: 'Board', value: 'board' },
      { text: 'Meeting', value: 'meeting' },
      { text: 'Date', value: 'date', width: '160px' },
      { text: 'Agenda Style', value: 'agendaStyle', width: '160px', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    headersFinalized: [
      { text: 'Board', value: 'board' },
      { text: 'Meeting', value: 'meeting' },
      { text: 'Date', value: 'date', width: '160px' },
      { text: 'Agenda Style', value: 'agendaStyle', width: '160px', sortable: false },
      { text: 'Actions', value: 'actions', sortable: false, width: '150px', align: 'center' }
    ],
    meetingSample: [
      { board: 'Regular Meeting', meeting: 'Label example ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonu.', menu: false, date: 'Sep 25,2020', time: '7:00PM', weekday: 'Tuesday', agendaStyle: 'Regular Meetings' },
      { board: 'Special Meeting', meeting: 'Label example ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonu.', menu: false, date: 'Sep 25,2020', time: '7:00PM', weekday: 'Tuesday', agendaStyle: 'Regular Meetings' },
      { board: 'Workshop', meeting: 'Label example ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonu.', menu: false, date: 'Sep 25,2020', time: '7:00PM', weekday: 'Tuesday', agendaStyle: 'Regular Meetings' },
      { board: 'Annual Report Meeting', meeting: 'Label example ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonu.', menu: false, date: 'Sep 25,2020', time: '7:00PM', weekday: 'Tuesday', agendaStyle: 'Regular Meetings' },
      { board: 'Monthly Report Meeting', meeting: 'Label example ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonu.', menu: false, date: 'Sep 25,2020', time: '7:00PM', weekday: 'Tuesday', agendaStyle: 'Regular Meetings' }
    ],
    boardsSample: ['City Manager Office', 'City Secretary Office', 'EDC Board', 'Town Board', 'Architectural Review Board', 'Planning and Zoning Commission', 'Library Board'],
    boardSelected: 'City Manager Office'
  }),
  methods: {
    test () {
      console.log(1)
    },
    viewMeeting () {
      this.modals.view = true
    },
    viewMeetingDraft () {
      this.modals.draft = true
    },
    openModal (item, optional) {
      if (optional) this.modeType = optional
      this.modals[item] = true
    },
    meetingUploads () {
      this.$router.push({ name: 'MeetingUploads' })
    },
    meetingMinutes () {
      this.$router.push({ name: 'MeetingMinutes' })
    },
    finalizeMeeting () {
      this.finalizingMeeting = true
    },
    viewFinalizeProgress () {
      this.openModal('finalizeProgress')
      this.finalizingMeeting = false
    },
    viewFinalized () {
      this.$router.push({ name: 'MeetingFinalized' })
    },
    viewMeetingItems () {
      this.$router.push({ name: 'MeetingItems' })
    },
    viewMeetingPacket () {
      this.$router.push({ name: 'MeetingPacket' })
    }
  }
})
</script>

<style>
  .v-menu__content {
    overflow-y: unset !important;
    overflow-x: unset !important;
    contain: unset !important;
  }
</style>
